import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Layout = ({ children, slug, pageTitle }) => {
  const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    siteUrl
                }
            }
        }
    `);

  return (
    <LayoutContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        {slug && (
        <link
          rel="canonical"
          href={data.site.siteMetadata.siteUrl + slug}
        />
        )}
      </Helmet>
      <HeaderDiv>
        <header className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </header>
      </HeaderDiv>
      <main>{children}</main>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
    width: calc(100% - 30px);
    display: block;
    font-family: sans-serif;
    margin: 15px;
`;

const HeaderDiv = styled.div`
    .site-title {
        font-size: 3rem;
        color: gray;
        font-weight: 700;
        padding: 2rem 0;
        margin: 1rem 0;
        border-radius: 25px;
        text-align: center;
            background: #e80046;
        a {
            text-decoration: none;
                color: white;
        }

        :hover {
            background: #e80046;
            a {
                color: white;
            }
        }
    }
`;

export default Layout;
